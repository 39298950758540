import { mapActions } from 'pinia';
import { useProcessesStore, useSidePanelStore } from '@/stores/index.js';
import Confirm from '@/components/modals/confirm.vue';
import StartProcessPanel from '../start-process-panel/index.vue';

export default {
  components: { StartProcessPanel },

  beforeDestroy() {
    this.resetPanels();
  },

  computed: {
    sidePanelName: () => 'StartProcess',
  },

  methods: {
    ...mapActions(useSidePanelStore, ['setPanel', 'resetPanels']),
    ...mapActions(useProcessesStore, ['readProcesses', 'deleteProcess', 'copyProcess']),

    select({ id }) {
      return this.$router.push({ name: 'DocumentsProcess', params: { processId: id } });
    },
    start({ id }) {
      this.setPanel(this.sidePanelName);
      this.startProcessId = id;
    },
    edit({ id }) {
      return this.$router.push({ name: 'DocumentsProcessEdit', params: { processId: id } });
    },
    async copy({ id, processName }) {
      this.modal = this.$modal.show(
        Confirm,
        {
          title: `Are you sure you want to duplicate \"${processName}\" process?`,
          onSubmit: async () => {
            const { data } = await this.copyProcess({ id });
            this.$router.push({ name: 'DocumentsProcessEdit', params: { processId: data.id } });
          },
        },
        {
          clickToClose: false, width: '440px', height: 'auto', adaptive: true, draggable: '.modal-handler',
        },
      );
    },
    async remove({ id, processName, goToProcessList = false }) {
      this.modal = this.$modal.show(
        Confirm,
        {
          title: `Are you sure you want to delete \"${processName}\" process?`,
          onSubmit: async () => {
            await this.deleteProcess({ id, processName });
            this.readProcesses();
            if (goToProcessList) {
              this.$router.push({ name: 'DocumentsProcesses' });
            }
          },
        },
        {
          clickToClose: false, width: '440px', height: 'auto', adaptive: true, draggable: '.modal-handler',
        },
      );
    },
  },
};
