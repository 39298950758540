<template>
  <div :class="$style.controls">
    <button
      v-if="showControl({ name: 'edit' })"
      type="button"
      class="button is-white"
      :class="$style.item"
      @click="$emit('edit')"
    >
      <font-awesome-icon
        :icon="['fal', 'pencil']"
        :class="$style.icon"
      />

      <span :class="$style.text">
        Edit
      </span>
    </button>

    <button
      v-if="showControl({ name: 'start' })"
      type="button"
      class="button is-white"
      :class="$style.item"
      @click="$emit('start')"
    >
      <font-awesome-icon
        :icon="['fal', 'play']"
        :class="$style.icon"
      />

      <span :class="$style.text">
        Start
      </span>
    </button>

    <button
      v-if="showControl({ name: 'delete' })"
      type="button"
      class="button is-white"
      :class="$style.item"
      @click="$emit('delete')"
    >
      <font-awesome-icon
        :icon="['fal', 'trash-can']"
        :class="$style.icon"
      />

      <span :class="$style.text">
        Delete
      </span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ProcessControls',
  props: {
    hide: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    showControl({ name }) {
      return !this.hide.includes(name);
    },
  },
};
</script>

<style lang="scss" module>
.controls {
  display: flex;
  align-items: center;

  .item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;

    .icon {
      margin-right: 10px;
      transition-duration: var(--transition-duration);
    }
  }
}
</style>
