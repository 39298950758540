<template>
  <div :class="$style.job">
    <start-process-panel
      :side-panel-name="sidePanelName"
      :process-id="startProcessId"
    />
    <div :class="$style.controls">
      <ProcessControls
        :hide="[loading && 'refresh']"
        @edit="edit({ id: processId })"
        @start="start({ id: processId })"
        @delete="removeProcess"
      />
    </div>

    <div :class="$style.subtitle">
      Run details
      <button
        type="button"
        title="Refresh"
        :class="$style.refresh"
        :disabled="loading"
        @click="refreshJob({ processId, jobId })"
      >
        <font-awesome-icon
          :class="{ [$style.rotate]: loading }"
          :icon="['fal', 'rotate']"
        />
      </button>
    </div>

    <div
      v-if="entities.length"
      :class="$style.list"
    >
      <b-table
        :data="entities"
        hoverable
      >
        <b-table-column
          v-slot="{ row: { timestamp, logLevel } }"
          sortable
          field="timestamp"
          label="Date"
        >
          <div :class="[$style.status, logLevel === 'Failed' && $style['status--failed']]">
            {{ formatDate({ date: timestamp, format: 'MMM DD, hh:mm A' }) }}
            ({{ fromDate({ date: timestamp }) }})
          </div>
        </b-table-column>

        <b-table-column
          v-slot="{ row: { message, logLevel } }"
          label="Message"
        >
          <div v-if="logLevel === 'Link'">
            Click to <a
              :href="message"
              target="_blank"
              rel="noopener noreferrer"
              @click.prevent="downloadFile(message, false)"
            >download result document.</a> The link is valid for 24 hours.
          </div>
          <div v-else-if="logLevel === 'Payload'">
            Click to <a
              :href="message"
              @click.prevent="downloadFile(message)"
            >download data sent to template.
            </a> The link is valid for 24 hours.
          </div>
          <div v-else>
            {{ message }}
          </div>
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia';
import { useProcessesStore, useErrorsStore } from '@/stores/index.js';
import processMixin from './mixins/process-mixin.js';
import ProcessControls from './components/process-controls.vue';
import { formatDate, fromDate } from '@/utils/index.js';

export default {
  name: 'Job',
  components: {
    ProcessControls,
  },
  mixins: [processMixin],
  props: {
    processId: {
      type: String,
      default: '',
    },
    jobId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      startProcessId: null,
    };
  },
  computed: {
    ...mapState(useProcessesStore, ['processName']),
    ...mapWritableState(useProcessesStore, ['job']),

    entities() {
      return this.job ? this.job.logEntities : [];
    },
    namePrefix() {
      return this.$route.query.namePrefix || null;
    },
  },
  watch: {
    jobId(jobId) {
      this.readJob({ processId: this.processId, jobId, namePrefix: this.namePrefix });
    },
  },
  created() {
    this.readJob({ processId: this.processId, jobId: this.jobId, namePrefix: this.namePrefix });
  },
  destroyed() {
    this.job = null;
  },
  methods: {
    formatDate,
    fromDate,
    ...mapActions(useProcessesStore, ['readJob']),
    ...mapActions(useErrorsStore, ['setReminder', 'setError']),
    async refreshJob({ processId, jobId }) {
      this.loading = true;

      await this.readJob({ processId, jobId, namePrefix: this.namePrefix });

      this.loading = false;
    },

    async removeProcess() {
      await this.remove({
        id: this.processId, processName: this.processName, goToProcessList: true,
      });
    },

    async downloadFile(uri, isPayload = true) {
      const link = document.createElement('a');
      if (isPayload) {
        link.download = 'payload.json';
      } else {
        const url = new URL(decodeURI(uri));
        const filename = url.searchParams.get('rscd')
          .split(';')
          .find((element) => element.startsWith('filename'))
          .split('"')[1];
        link.download = filename;
      }
      try {
        const response = await fetch(uri);

        if (response.status === 403) {
          this.setReminder({ message: 'The file has been removed. It is available within 24 hours only.' });
          return;
        }

        const blob = await response.blob();
        link.href = window.URL.createObjectURL(new Blob([blob]));
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (e) {
        this.setError({ message: e.message });
      }
    },
  },
};
</script>

<style lang="scss" module>
.job {
  position: relative;

  .controls {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .subtitle {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 36px;
  }

  .status {
    &--failed {
      color: var(--color-danger);
    }
  }
}

@media (max-width: 768px) {
  .job {
    :global(.table-mobile-sort) {
      display: none;
    }
  }
}

.refresh {
  position: relative;
  right: 5px;
  bottom: 1px;
  border: none;
  padding: 10px;
  background: transparent;
  font-size: 16px;
  color: inherit;
  cursor: pointer;

  &:disabled {
    cursor: auto;
  }

  &:hover {
    color: hsl(0deg, 0%, 4%);
  }
}

.rotate {
  animation-duration: 500ms;
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
